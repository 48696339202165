import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Container, BaseStyles } from "theme-ui";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Container variant="copy" as={BaseStyles} py={4} fontSize={3} mdxType="Container">
      <h1>{`Hey there!`}</h1>
      <p>{`This is a site for experimental new workshops using `}<a parentName="p" {...{
          "href": "https://github.com/jxnblk/mdx-deck"
        }}>{`MDX Deck`}</a>{` + Gatsby.`}</p>
      <p>{`Source: `}<a parentName="p" {...{
          "href": "https://github.com/hackclub/workshop-deck-playground"
        }}>{`https://github.com/hackclub/workshop-deck-playground`}</a></p>
      <h2>{`Workshops`}</h2>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/personal-website"
          }}>{`Personal Website`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/team-liquid-workshop"
          }}>{`Team Liquid Personal Website`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/kahoot"
          }}>{`Hacking Kahoot`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/h1-demo"
          }}>{`h1 Demo`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/generative-art"
          }}>{`Generative Art`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/vtuber"
          }}>{`Vtuber`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/sprig"
          }}>{`Sprig: Catch Orpheus`}</a></li>
      </ul>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      